// Configurazione griglia
export const GRID_CONFIG = {
  columns: 12,
  gutterWidth: 10,
  containerWidth: 600
};

// Configurazione per layout full-width
export const FULL_WIDTH_CONFIG = {
  columns: 12,
  gutterWidth: 10,
  containerWidth: '100%'
};

// Dimensioni predefinite per ogni tipo di blocco
export const BLOCK_DIMENSIONS = {
  text: {
    defaultColumns: 12,
    minColumns: 1,
    maxColumns: 12
  },
  image: {
    defaultColumns: 6,
    minColumns: 2,
    maxColumns: 12
  },
  button: {
    defaultColumns: 4,
    minColumns: 2,
    maxColumns: 12,
    defaultColumns: 4
  },
  spacer: {
    defaultColumns: 12,
    minColumns: 1,
    maxColumns: 12
  },
  divider: {
    defaultColumns: 12,
    minColumns: 1,
    maxColumns: 12
  }
};

// Utility functions
export const calculateColumnWidth = (columns) => {
  return `${(columns / GRID_CONFIG.columns) * 100}%`;
};

export const getGridColumnSpan = (columns) => {
  return `span ${columns}`;
}; 