const state = {
  showContentPanel: false,
  content: null,
  actionTitle: null,
  panelType: null
};

const mutations = {
  TOGGLE_CONTENT_PANEL(state, value) {
    state.showContentPanel = value;
  },
  SET_CONTENT(state, { content, actionTitle, panelType }) {
    state.content = content;
    state.actionTitle = actionTitle;
    state.panelType = panelType;
  },
  CLEAR_CONTENT(state) {
    state.content = null;
    state.actionTitle = null;
    state.panelType = null;
  },
};

const actions = {
  togglePanel({ commit }, value) {
    commit('TOGGLE_CONTENT_PANEL', value);
  },
  setContent({ commit }, { content, actionTitle, panelType }) {
    commit('SET_CONTENT', { content, actionTitle, panelType });
    commit('TOGGLE_CONTENT_PANEL', true);
  },
  clearContent({ commit }) {
    commit('CLEAR_CONTENT');
    commit('TOGGLE_CONTENT_PANEL', false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}; 